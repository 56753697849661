import * as React from "react";
import { Link, withPrefix } from "gatsby";
import * as styles from "./Author.module.scss";

type Props = {
  author: {
    name: string,
    bio: string,
    photo: string
  },
  isIndex?: boolean
};

const Author = ({
                  author,
                  isIndex
                }: Props) => (
  <div className={styles["author"]}>
    <Link to="/">
      <img
        src={withPrefix(author.photo)}
        className={styles["author__photo"]}
        alt={author.name}
      />
    </Link>

    {isIndex === true ? (
      <h1 className={styles["author__title"]}>
        <Link className={styles["author__titleLink"]} to="/">{author.name}</Link>
      </h1>
    ) : (
      <h2 className={styles["author__title"]}>
        <Link className={styles["author__titleLink"]} to="/">{author.name}</Link>
      </h2>
    )}
    <div>
      {author.bio.split("\n")
        .map((line, index) => (
          <p key={index} className={styles["author__subtitle"]}>{line}</p>
        ))}

    </div>
  </div>
);

export default Author;
